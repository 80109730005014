import React from "react";
import styled from "styled-components";

import { Card } from "site-ui/src/components/Card";
import Link from "gatsby-link";
import { getSubcategoriesList } from "site-ui/src/helpers/getSubcategoriesList";
import { useTranslation } from "twill-net-localization";

const LinkRenderer = ({ children, href }) => <Link to={href}>{children}</Link>;

export const FaqCategoryList = ({ categories }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {categories.map((category) => (
        <Card
          heading={category.name}
          isHorizontal
          href={category.seoMeta.slug}
          text={t("common.categoryCount", {
            count: getSubcategoriesList(category).length,
            defaultValue: "{{count}} category",
            defaultValue_plural: "{{count}} categories",
          })}
          Link={LinkRenderer}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 16px;
  }
  @media (min-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    & > * {
      max-width: calc(33.3% - 15px);
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;
