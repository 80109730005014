import React, { ReactNode, FC } from "react";
import styled, { css } from "styled-components";
import {
  white,
  darkBlue3,
  darkBlue,
  lightBlue,
  textColor,
} from "site-ui/colors";
import { Typography } from "@material-ui/core";

type LinkProps = {
  href?: string;
  children?: ReactNode;
};

type CtaType = {
  hasText?: boolean;
};

export interface CardProps {
  image?: ReactNode;
  heading: string;
  text?: ReactNode;
  cta?: string;
  href?: string;
  Link?: FC<LinkProps>;
  onClick?: () => void;
  isHorizontal?: boolean;
  heroImage?: ReactNode;
  labels?: ReactNode[];
  label?: string;
  icon?: ReactNode;
}

const DefaultLink: FC = ({ children }) => children;

export const Card: FC<CardProps> = ({
  image,
  heading,
  text,
  cta,
  href,
  Link,
  isHorizontal = false,
  onClick,
  heroImage,
  labels,
  label,
  ...props
}) => {
  const LinkRender = Link || DefaultLink;
  return (
    <CardBase onClick={onClick} hasLink={!!Link} isHorizontal={isHorizontal}>
      <LinkRender href={href} {...props}>
        <CardInner>
          {heroImage && <Hero>{heroImage}</Hero>}
          <CardContent isHorizontal={isHorizontal}>
            {image && (
              <ImageWrapper hasBgColor={!isHorizontal}>{image}</ImageWrapper>
            )}
            <Content>
              {label && <PreLabel>{label}</PreLabel>}
              <h4>{heading}</h4>
              {text && <Text>{text}</Text>}
              {labels && <Labels>{labels}</Labels>}
            </Content>
            {!!Link && <Cta hasText={!!cta}>{cta}&nbsp;&nbsp;&rarr;</Cta>}
          </CardContent>
        </CardInner>
      </LinkRender>
      <Bar>
        <Line />
      </Bar>
    </CardBase>
  );
};

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PreLabel = styled.div`
  font-size: 14px;
  color: ${textColor};
  margin-bottom: 8px;
  @media (min-width: 480px) {
    margin-bottom: 16px;
  }
`;

const Hero = styled.div<{ bg: string }>`
  img {
    max-height: 230px;
  }
`;

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  & > * {
    margin-right: 8px;
  }
`;

const ImageWrapper = styled.div<{ hasBgColor: string }>`
  margin-bottom: 16px;
  margin-right: 16px;
  overflow: hidden;
  border-radius: 64px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  img {
    width: 32px;
  }
  ${(props) =>
    props.hasBgColor &&
    css`
      background-color: ${lightBlue};
      height: 64px;
      width: 64px;
      img {
        filter: brightness(100);
      }
    `}
`;

const Text = styled.div`
  flex-grow: 2;
  flex-basis: 100%;
  font-size: 14px;
  font-weight: 200;
  p {
    margin: 0;
  }
  margin-bottom: 16px;
`;

const Content = styled.div`
  flex-grow: 2;
`;

const Cta = styled.div<CtaType>`
  color: ${lightBlue};
  font-size: 16px;
  font-weight: bold;
  align-self: flex-end;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  position: absolute;
  right: 24px;
  bottom: 20px;
  ${({ hasText }) =>
    hasText &&
    css`
      font-size: 16px;
      position: static;
    `}
`;

const CardContent = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: ${darkBlue};
  @media (min-width: 360px) {
    padding: 24px;
    flex-direction: ${({ isHorizontal }) => (!isHorizontal ? `column` : `row`)};
  }
  @media (min-width: 1440px) {
    padding: 32px;
  }
`;

const Bar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  overflow: hidden;
`;
const Line = styled.div`
  background-color: ${lightBlue};
  height: 100%;
  transition: transform 0.5s, opacity 0.3s;
  opacity: 0;
  transform: translate(-100%, 0);
  font-size: 0;
`;

const hoverCSS = css`
  transform: translate(0, -8px);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1), 2px 0 10px 0 rgba(0, 0, 0, 0.05),
    3px 9px 16px 0 rgba(0, 0, 0, 0.13);
  ${Line} {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

export const CardBase = styled.div<CardProps>`
  position: relative;
  z-index: 1;
  min-height: 100%;
  background-color: rgb(255, 255, 255);
  transition: all 0.4s ease 0s;
  transform: translate3d(0px, 0px, 0px);
  overflow: hidden;
  box-shadow: 0;
  border: 1px solid ${darkBlue3};
  background-color: ${white};
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  & > *:first-child {
    height: 100%;
    flex-basis: 100%;
  }
  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      ${ImageWrapper} {
        margin-bottom: 0;
      }
      ${Text} {
        padding-right: 32px;
        margin-bottom: 0;
        color: ${darkBlue};
      }
      @media (max-width: 360px) {
        h4 {
          font-size: 16px;
        }
      }
    `}
  h4 {
    color: ${darkBlue};
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    margin-top: 0;
    @media (min-width: 375px) {
      font-size: 20px;
    }
  }
  height: 100%;
  p {
    color: ${darkBlue};
  }

  ${({ hasLink }) =>
    hasLink &&
    css`
      &:hover {
        ${hoverCSS}
      }
    `}
`;
