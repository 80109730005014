import React from "react";
import { graphql } from "gatsby";
import { LocaleLink as Link } from "gatsby-theme-twill-website";
import { Header } from "../components/Header";
import { Accordion } from "site-ui/src/components/Accordion/index";
import { renderPlainText, renderRichText } from "twill-contentful-text-render";
import { Section } from "site-ui/src/components/Section";
import { backgroundGrey } from "site-ui/colors";
import { FaqCategoryList } from "site-ui/src/components/FaqCategoryList";
import Helmet from "react-helmet";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { Typography } from "@material-ui/core";
import { useTranslation } from "twill-net-localization";

export default ({ data }) => {
  const {
    contentfulFaqMainPageSettings: {
      headline,
      headerText,
      categories,
      featuredQuestions,
    },
  } = data;
  const questions = featuredQuestions.map(question => ({
    ...question,
    question: question.question.question,
    answer: renderRichText(question.answer),
    answerText: renderPlainText(question.answer),
  }));
  const schema = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: questions.map(question => ({
      "@type": "Question",
      name: question.question,
      text: question.question,
      dateCreated: "2020-08-12T21:11Z",
      author: {
        "@type": "Organization",
        name: "Twill.net",
      },
      acceptedAnswer: {
        "@type": "Answer",
        text: [question.answerText],
        dateCreated: "2020-08-12T21:11Z",
        url: "https://www.twill.net/faq/",
        author: {
          "@type": "Organization",
          name: "Twill.net",
        },
      },
    })),
  };

  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Header headline={headline} text={headerText} />
      <Section backgroundColor={backgroundGrey} padding={1}>
        <FaqCategoryList Link={Link} categories={categories} />
      </Section>
      <Section
        align="center"
        heading={
          <Typography gutterBottom variant="h2">
            {t("common.faqLong", "Frequently Asked Questions")}
          </Typography>
        }
      >
        <Accordion questions={questions} />
      </Section>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query FAQMainPageQuery($locale: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulFaqMainPageSettings(node_locale: { eq: $locale }) {
      id
      headline
      headerText
      node_locale
      categories {
        id
        contentful_id
        name
        slug
        subCategories {
          contentful_id
        }
        subcategory {
          contentful_id
        }
        image {
          localFile {
            id
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 138
                placeholder: BLURRED
                transformOptions: { fit: CONTAIN }
                layout: FIXED
              )
            }
          }
        }
        seoMeta {
          slug
        }
      }
      featuredQuestions {
        id
        seoMeta {
          slug
        }
        question {
          question
        }
        answer {
          raw
        }
      }
    }
  }
`;
